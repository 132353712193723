/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Hayden's Portfolio",
  description:
    "As a person with a passion for software engineering, I am excited about the prospect of working in this field one day. I am eager to learn and work with new technologies, and am committed to creating efficient, reliable software. I have a strong foundation in computer science and am dedicated on staying up-to-date with the latest developments in the field.",
  og: {
    title: "Hayden Doherty Portfolio",
    type: "website",
    url: "https://github.com/haydend01",
  },
};

//Home Page
const greeting = {
  title: "Hayden Doherty",
  logo_name: "HaydenDoherty",
  nickname: "Computer Science",
  subTitle:
    "As a person with a passion for software engineering, I am excited about the prospect of working in this field one day. I am eager to learn and work with new technologies, and am committed to creating efficient, reliable software. I have a strong foundation in computer science and am dedicated on staying up-to-date with the latest developments in the field.",
  resumeLink:
    "https://drive.google.com/file/d/1OeTmA5zNdAXsfaenAC6xpr9XjXFjbtOm/view?usp=sharing",
  portfolio_repository: "https://github.com/haydend01",
  githubProfile: "https://github.com/haydend01",
};

const socialMediaLinks = [
  /* Your Social Media Link */

  //"https://github.com/haydend01",
  //"https://www.linkedin.com/in/hayden-doherty-93256b164/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/haydend01",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/hayden-doherty-93256b164/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:haydentdoherty@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "About Me",
      fileName: "DataScienceImg",
      skills: [
        "⚡ My name is Hayden Doherty, I am a 21 year old software programmer and avid connoisseur of tech related material.",
        "⚡ I have been studying programming for about a 10 years now. Stemming from my fathers work, making websites with HTML. Growing up I would assist with some of the workload.",
        "⚡ Throughout high school I took a few classes with Javascript and Python, which only further solidified my interest in software engineering.",
        "⚡ Entering college I decided to study Computer Science with a great interest in Cyber Security.",
      ],
      softwareSkills: [
        {
          skillName: "HTML",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Javascript",
          fontAwesomeClassname: "logos-javascript",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Programming Languages",
      fileName: "FullStackImg",
      skills: [
        "⚡ Personal favorite project is Tic Tac Toe, I try to make a version in every language I learn ex. C++, Python, Java, etc.",
        "⚡ C++ as been a personal favorite because of its usability within the Cyber Security Industry.",
        "⚡ Implementing Big-O-Notation in terms of efficiency with readability as an added thought within every line of code written.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#0000AA",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ As a UI/UX design person, I have been responsible for creating engaging and intuitive user interfaces for websites and applications.",
        "⚡ Work sometimes involved conducting user research to understand the needs and goals of the people using the product, and using that information to create designs that are easy to use and aesthetically pleasing.",
        "⚡ I have a strong understanding of graphic design principles and the latest design tools, as well as the ability to think creatively and problem-solve.",
      ],
      softwareSkills: [
        {
          skillName: "Adobe Lightroom",
          fontAwesomeClassname: "simple-icons:adobelightroom",
          style: {
            color: "00008f",
          },
        },
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#CC33CC",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            color: "#00008f",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/haydentdoherty?hr_r=1",
    },
    {
      siteName: "Google",
      iconifyClassname: "simple-icons:google",
      style: {
        color: "#6666BB",
      },
      profileLink:
        "https://www.coursera.org/account/accomplishments/professional-cert/9WNU9H2GEX65?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=prof",
    },
    {
      siteName: "Code Wars",
      iconifyClassname: "simple-icons:codewars",
      style: {
        color: "#BB0000",
      },
      profileLink: "https://www.codewars.com/users/haydend01",
    },
    {
      siteName: "Leet Code",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#CCCCCC",
      },
      profileLink: "https://leetcode.com/haydend01/",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Cal State University Fullerton",
      subtitle: "B.S. Computer Science",
      logo_path: "csuf_logo.png",
      alt_name: "CSUF",
      duration: "2020 - Present",
      descriptions: [
        "⚡ As a student in this program, I have learned a wide range of topics, including programming languages, algorithms, data structures, computer systems, and software engineering.",
        "⚡ In this program, I have been able to learn how to design, implement, and evaluate software systems that are efficient, reliable, and secure. I also have gained hands-on experience with a variety of computer science tools and technologies, including programming languages such as Java and Python, and development environments such as Eclipse and Visual Studio.",
        "⚡ Graduating from the program means I will have the knowledge and skills needed to pursue a wide range of careers in the field of computer science, including software development, systems engineering, and data analysis. I feel well-prepared for further study at the graduate level, including in specialized areas such as artificial intelligence, machine learning, and cybersecurity.",
      ],
      website_link: "https://www.fullerton.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Java Programming",
      subtitle: "- OCC Cert.",
      logo_path: "java.png",
      certificate_link: "#",
      alt_name: "java cert.",
      color_code: "#FFFDD0",
    },
    {
      title: "IT Support",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/professional-cert/9WNU9H2GEX65?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=prof",
      alt_name: "Google",
      color_code: "#EEEEEE",
    },
    {
      title: "C++ Programming",
      subtitle: "- OCC Cert.",
      logo_path: "cplusplus_logo.png",
      certificate_link: "#",
      alt_name: "c++ cert.",
      color_code: "#c9d3ff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I am hopeful for my future, through the connection of working in the hospitality industry and moving to the tech field is the transferable skills that are developed in the hospitality industry. Working in the hospitality industry often required myself to further develop strong communication skills, problem-solving abilities, and the ability to work well in a team. These are all skills that are also valuable in the tech industry. Additionally, both industries often require employees to be adaptable and to be able to learn new things quickly. This will make the transition from working in the hospitality industry to the tech field easier for myself throughout my career.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Brand Specialist Supervisor",
          company: "Brand Enhance Parking and Hospitality",
          company_url: "http://brandenhanceparking.com/",
          logo_path: "brand_enhance.png",
          duration: "Jan 2020 - PRESENT",
          location: "Irvine, California",
          description:
            "Working as a supervisor at a hotel can be a challenging but rewarding role. As a supervisor, I am responsible for managing the work of a team of valet attendants, ensuring that they provide high-quality service to hotel guests. This could involve assigning tasks, providing guidance and support to the team, and monitoring their performance to ensure that the valet service meets the standards set by the hotel and the valet company.",
          color: "#0879bf",
        },
        {
          title: "Valet Attendant",
          company: "Towne Park",
          company_url: "https://www.townepark.com/",
          logo_path: "towne_park.png",
          duration: "Aug 2019 - Jan 2020",
          location: "Newport, California",
          description:
            "Working as a valet involves parking and retrieving guests' vehicles. The job requires myself to develop excellent customer service skills and the ability to multitask. As a valet, I am responsible for greeting guests as they arrive at the hotel, opening their car doors, and assisting with their luggage. I will then park the guests' vehicles in one of our designated area's and ensure that they are safe and secure. When guests are ready to leave, I retrieve their vehicles and return them to the hotel entrance. In this role, I was able to work quickly and efficiently in a fast-paced environment.",
          color: "#9b1578",
        },
        {
          title: "Bus Boy",
          company: "Old World Restaurant Huntington Beach",
          company_url: "https://www.oldworldhb.com/",
          logo_path: "old_word.png",
          duration: "Jun 2016 - Aug 2017",
          location: "Huntington Beach, Calfornia",
          description:
            "Working as a bus boy at Old World German Restaurant was a rewarding and exciting job. As a bus boy, I was responsible for maintaining the cleanliness and overall appearance of the restaurant, including tasks such as setting tables, clearing dirty dishes, and sweeping and mopping the floors. I was also responsible for refilling drinks and assisting the waitstaff with other tasks as needed. Working at Old World, I had the opportunity to learn about traditional German cuisine and culture, and had the chance to interact with customers and learn more about their experiences and backgrounds. Overall, working as a bus boy at Old World German restaurant was a great way to gain experience in the food service industry and learn valuable skills.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Coming Soon!",
          company: "To be Determined",
          company_url: "#",
          logo_path: "coming_soon.png",
          duration: "To be Determined - RECENT",
          location: "Irvine, California",
          description: "Overall, I am hopeful about my prospects for pursuing an internship in either software engineering or cyber security, and I am eager to take on the challenges and opportunities that these fields have to offer. I am confident that with hard work, dedication, and a passion for technology, I will be able to make a meaningful contribution to the field, and I am excited to see where any internship's will take me.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Big Creek Missions",
          company: "Big Creek",
          company_url: "https://www.bigcreekmissions.com/",
          logo_path: "big_creek.png",
          duration: "Nov 2016 - Nov 2019",
          location: "Big Creek, Kentucky",
          description:
            "While spending time along the Appalachian mountain trail, I volunteered with Big Creek Missions and helped the local communities in various ways. This included providing assistance with everyday tasks, such as cooking and cleaning, as well as helping with community projects and events. I also spent time getting to know the residents and listening to their stories, which was a rewarding experience. Overall, my time volunteering with Big Creek Missions was incredibly fulfilling and allowed me to make a positive impact on the lives of those living in the Appalachian mountain trail area.",
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Welcome to my coding projects page! Here, you'll find a collection of my work in various programming languages, including C++, ReactJS, Java, Python, and JavaScript. I'm always learning and experimenting with new technologies, so you may also see projects in other languages as well. Each project showcases my skills and abilities as a developer, as well as my passion for creating innovative and functional solutions to real-world problems. Whether it's a simple command-line tool or a complex web application, I strive to produce high-quality code that is both effective and efficient.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {};

const publications = {
  data: [],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "hayden_closeup.png",
    description:
      "Welcome to my contact page! My name is Hayden Doherty and I am here to help you connect with me. You can reach me at my email address, which is gmail. Just send me a message and I will get back to you as soon as possible. If you prefer to connect with me on social media, you can find me on Git and LinkedIn. Just send me a request and we can start networking.",
  },
  blogSection: {
    title: "Linkedin",
    subtitle:
      "Hi there! I would love to connect with you on LinkedIn. Please feel free to send me an invitation to connect and we can keep in touch and network. Looking forward to hearing from you!",
    link: "https://www.linkedin.com/in/hayden-doherty-93256b164/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "175 N Wayfield St, Orange, CA 92867",
    avatar_image_path: "address_image.svg",
    location_map_link:
      "https://www.google.com/maps/place/Holiday+Skate+Center/@33.7892841,-117.8326808,15z/data=!4m5!3m4!1s0x0:0xc624b4d8852af2d9!8m2!3d33.789284!4d-117.8326711",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "(714)-475-8693",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
